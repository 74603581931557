<template>
  <div>
    <!-- 加载图片时的进度条 -->
    <a-modal 
    :visible="lodaingVisible"
    :footer="null"
    :closable="false">
      <a-spin >
      <a-alert a-alert
        message="正在上传"
        description="请不要关闭窗口"
      ></a-alert>
      </a-spin>
    </a-modal>
    
    <div class="top-nav">
      <a-config-provider :locale="zhCN">
        <a-range-picker
            style="width: 400px"
            format="YYYY-M-D"
            :placeholder="['起始时间','终止时间']"
            :value="createValue"
            @change="onChange"
            @ok=";"
        />
      </a-config-provider>
      <a-button class="search_btn" @click="searchTable">
        <template #icon>
          <SearchOutlined/>
        </template>
        搜索
      </a-button>
      <a-button ref="btnRef" v-if="deleteControl" type="dashed" class="table_btn" @click="searchRemakeReport">
        <template #icon>
          <DeleteOutlined />
        </template>
        查看撤销请求
      </a-button>
    </div>
    <div style="padding: 5px 20px">
        <a-button type="primary" style="margin-left:20px" @click="rubbishManage(true)">
        海漂垃圾治理情况报送
      </a-button>
      <a-button type="primary" style="margin-left:20px" @click="rubbishManage(false)">
        海滩垃圾治理情况报送
      </a-button>
    </div>
    <a-table
        v-show="!isRemakeTable"
        :columns="columns"
        :data-source="data"
        :pagination='pagination'
        :loading="loading"
        :row-key="(record) => record.id"
        @change="changeMessageData"
    >
      <template #action="{ text }">
        <a-button type="primary" class="btn" @click="showDetails(text)">展示报表详情</a-button>
        <a-button class="btn" @click="exportWordData(text.id)">导出报表详细信息</a-button>
        <a-button type="danger" v-if="deleteControl" class="btn" @click="deleteTable(text)">删除</a-button>
        <a-button type="primary"  v-if="!deleteControl" class="btn" @click="deleteRequest(text)">撤销申请</a-button>
      </template>
    </a-table>
    <a-table
        v-show="isRemakeTable"
        :columns="columns1"
        :data-source="data1"
        :pagination='pagination1'
        :loading="loading1"
        :row-key="(record) => record.id"
        @change="changeMessageData1"
    >
      <template #action="{ text }">
        <a-button type="primary" class="btn" @click="showDetails(text)">展示报表详情</a-button>
        <a-button class="btn" @click="exportWordData(text.id)">导出报表详细信息</a-button>
        <a-button type="danger" v-if="deleteControl" class="btn" @click="deleteTable(text)">删除</a-button>
        <a-button type="primary"  v-if="!deleteControl" class="btn" @click="deleteRequest(text)">撤销申请</a-button>
      </template>
    </a-table>
    <!-- 填送报表的拟态框 -->
    <a-modal
        :title=modalTitle
        width="1000px"
        :visible="addVisible"
        @ok="doAddKeywords"
        @cancel="handleCancel"
        cancelText="取消"
        okText="确认"
    >
      <div style="height:315px">
        <div style="width: 45% ;float: left">
          <ul class="input_list">
            <li>
              <span>潮汐：</span>
              <a-input v-model:value="inputData.tide" placeholder="请输入潮汐相关数据"/>
            </li>
            <li>
              <span>风向：</span>
              <a-input v-model:value="inputData.windDirection" placeholder="请输入风向"/>
            </li>
            <li>
              <span>风速：</span>
              <a-input v-model:value="inputData.windSpeed" placeholder="请输入风速"/>
            </li>
            <li>
              <span>日期和时间：</span>
              <a-config-provider :locale="zhCN">
                <a-range-picker
                    style="width: 300px"
                    format="YYYY-MM-DD"
                    :placeholder="['起始时间','终止时间']"
                    :value="upCreateValue"
                    @change="upOnChange"
                    @ok=";"
                />
              </a-config-provider>
            </li>
            <li>
              <span>区块：</span>
              <a-select
                  v-model:value="inputData.rubbishTypeList[0].regionId"
                  style="width:140px; margin-right: 20px"
                  placeholder="请选择区域"
                  v-show="modalId === false"
              >
                <a-select-option value='1'>区块1</a-select-option>
                <a-select-option value='2'>区块2</a-select-option>
                <a-select-option value='3'>区块3</a-select-option>
                <a-select-option value='4'>区块4</a-select-option>
                <a-select-option value='5'>区块5</a-select-option>
                <a-select-option value='6'>区块6</a-select-option>
                <a-select-option value='7'>区块7</a-select-option>
                <a-select-option value='8'>区块8</a-select-option>
              </a-select>
              <a-select
                  v-model:value="inputData.rubbishTypeList[0].regionId"
                  style="width:140px; margin-right: 20px"
                  placeholder="请选择区域"
                  v-show="modalId === true"
              >
                <a-select-option value='1'>区块A(1,2,3)</a-select-option>
                <a-select-option value='4'>区块B(4,5)</a-select-option>
                <a-select-option value='6'>区块C(6,7,8)</a-select-option>
              </a-select>
            </li>
            <li>
              <span v-if="modalId === false">沙滩沉积类型：</span>
              <a-select
                  v-model:value="inputData.sedimentation_type"
                  style="width: 150px"
                  placeholder="请选择沙滩沉积类型"
                  v-show="modalId === false">
                <a-select-option value='1'>砾石</a-select-option>
                <a-select-option value='2'>粗砂</a-select-option>
                <a-select-option value='3'>细粉沙</a-select-option>
                <a-select-option value='4'>粘土</a-select-option>
              </a-select>
            </li>
          </ul>
        </div>
        <div style="width: 50%; height:100%; float:right">
          <a-upload
              list-type="picture-card"
              v-model:fileList="fileList"
              @preview="handlePreview"
              :beforeUpload="beforeUpload"
              :multiple="true"
              :remove="handleRemove"
              @change="handleChange"
          >
            <div v-if="fileList.length < 12">
              <PlusOutlined/>
              <div class="ant-upload-text">添加图片</div>
            </div>
          </a-upload>
          <!-- 放大预览窗口 -->
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelImg">
            <img alt="example" style="width: 100%" :src="previewImage"/>
          </a-modal>
        </div>
      </div>
      <div>
       <!-- <div style="disply: flex; justify-content: space-around; align-items: center; border: 1px solid black;">
        <div style="font-weight:800; margin-right:20px; border: 1px solid black">垃圾类型统计</div>
        <div style="font-weight:800; margin-right:20px; border: 1px solid black">垃圾数量（个）</div>
        <div style="font-weight:800; margin-right:20px; border: 1px solid black">垃圾质量（kg）</div>
        <div style="font-weight:800; margin-right:20px; border: 1px solid black">备注</div>
       </div> -->
       <ul></ul>
        <ul class="input_list">
            <li id="title-table">
            <span id="first">垃圾类型统计</span>
            <span id="common">垃圾数量（个）</span>
            <span id="common">垃圾质量（kg）</span>
            <span id="note">备注</span>
            </li>
          <li id="table">
            <span id="first">塑料类</span>
            <a-input 
            v-model:value="inputData.rubbishTypeList[0].num" placeholder="垃圾数量(个)"
            style="width: 120px; margin-right: 20px"  
            ></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[0].weight" placeholder="垃圾质量(kg)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[0].message" placeholder="备注"
                     style="width: 220px;"></a-input>
          </li>
          <li id="table">
            <span id="first">聚苯乙烯泡沫塑料类</span>
            <a-input v-model:value="inputData.rubbishTypeList[1].num" placeholder="垃圾数量(个)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[1].weight" placeholder="垃圾质量(kg)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[1].message" placeholder="备注"
                     style="width: 220px;"></a-input>
          </li>
          <li id="table">
            <span id="first">玻璃类</span>
            <a-input v-model:value="inputData.rubbishTypeList[2].num" placeholder="垃圾数量(个)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[2].weight" placeholder="垃圾质量(kg)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[2].message" placeholder="备注"
                     style="width: 220px;"></a-input>
          </li>
          <li id="table">
            <span id="first">金属类</span>
            <a-input v-model:value="inputData.rubbishTypeList[3].num" placeholder="垃圾数量(个)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[3].weight" placeholder="垃圾质量(kg)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[3].message" placeholder="备注"
                     style="width: 220px;"></a-input>
          </li>
          <li id="table">
            <span id="first">橡胶类</span>
            <a-input v-model:value="inputData.rubbishTypeList[4].num" placeholder="垃圾数量(个)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[4].weight" placeholder="垃圾质量(kg)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[4].message" placeholder="备注"
                     style="width: 220px;"></a-input>
          </li>
          <li id="table">
            <span id="first">织物（布）类</span>
            <a-input v-model:value="inputData.rubbishTypeList[5].num" placeholder="垃圾数量(个)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[5].weight" placeholder="垃圾质量(kg)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[5].message" placeholder="备注"
                     style="width: 220px;"></a-input>
          </li>
          <li id="table">
            <span id="first">木制品类</span>
            <a-input v-model:value="inputData.rubbishTypeList[6].num" placeholder="垃圾数量(个)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[6].weight" placeholder="垃圾质量(kg)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[6].message" placeholder="备注"
                     style="width: 220px;"></a-input>
          </li>
          <li id="table">
            <span id="first">纸类和其他人造物品</span>
            <a-input v-model:value="inputData.rubbishTypeList[7].num" placeholder="垃圾数量(个)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[7].weight" placeholder="垃圾质量(kg)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[7].message" placeholder="备注"
                     style="width: 220px;"></a-input>
          </li>
          <li id="table">
            <span id="first">无法辨识的材料</span>
            <a-input v-model:value="inputData.rubbishTypeList[8].num" placeholder="垃圾数量(个)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[8].weight" placeholder="垃圾质量(kg)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishTypeList[8].message" placeholder="备注"
                     style="width: 220px;"></a-input>
          </li>
          <li id="title-table2">
            <span id="first">垃圾尺寸</span>
            <span id="common">垃圾数量（个）</span>
            <span id="note">备注</span>
          </li>
          <li id="table2">
            <span id="first">小块垃圾（尺寸&lt;2.5cm）</span>
            <a-input v-model:value="inputData.rubbishSizeList[0].num" placeholder="垃圾数量(个)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishSizeList[0].message" placeholder="备注"
                     style="width: 220px;"></a-input>
          </li>
          <li id="table2">
            <span id="first">中块垃圾（尺寸&gt;=2.5cm 且 &lt;=10cm）</span>
            <a-input v-model:value="inputData.rubbishSizeList[1].num" placeholder="垃圾数量(个)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishSizeList[1].message" placeholder="备注"
                     style="width: 220px;"></a-input>
          </li>
          <li id="table2">
            <span id="first">大块垃圾（尺寸&gt;10cm 且 &lt;=1m）</span>
            <a-input v-model:value="inputData.rubbishSizeList[2].num" placeholder="垃圾数量(个)"
                     style="width: 120px; margin-right: 20px"></a-input>
            <a-input v-model:value="inputData.rubbishSizeList[2].message" placeholder="备注"
                     style="width: 220px;"></a-input>
          </li>
          <li id="table2">
            <span id="first">特大块垃圾（尺寸&gt;1m）</span>
            <a-input v-model:value="inputData.rubbishSizeList[3].num" placeholder="垃圾数量(个)"
                     style="width: 120px; margin-right: 20px">
            </a-input>
            <a-input v-model:value="inputData.rubbishSizeList[3].message" placeholder="备注"
                     style="width: 220px;">
            </a-input>
          </li>
        </ul>
      </div>
    </a-modal>
    <!-- 填报数据时检验的提醒弹框 -->
    <a-modal
      title="请核对数据格式"
      :visible="validVisible4"
      :footer="null"
       @cancel="handleCancel4"
    >
    <div style="color: red">区块不可为空！</div>
    </a-modal>
    <a-modal
      title="请核对数据格式"
      :visible="validVisible1"
      :footer="null"
       @cancel="handleCancel1"
    >
    <div style="color: red">垃圾数量为整数 ！</div>
    </a-modal>
    <a-modal
      title="请核对数据格式"
      :visible="validVisible2"
      :footer="null"
      @cancel="handleCancel2"
    >
    <div style="color: red">垃圾重量为数字（包含小数）！ </div>
    </a-modal>
    <a-modal
      title="请核对数据格式"
      :visible="validVisible3"
      :footer="null"
      @cancel="handleCancel3"
    >
    <div style="color: red">垃圾数量为整数！垃圾重量为数字（包含小数）！</div>
    </a-modal>
    <!-- 展示数据的拟态框 -->
    <a-modal
        title='数据展示'
        width="1000px"
        :visible="showVisible"
        @ok="showConfirm"
        @cancel="showCancel"
        cancelText="取消"
        okText="确认"
        :footer="null"
    >
      <ul class="input_list">
        <li>
          <span>填报人：</span>
          <a-input v-model:value="showData.name" disabled/>
        </li>
        <li>
          <span>联系方式：</span>
          <a-input v-model:value="showData.phone" disabled/>
        </li>
        <li>
          <span>潮汐：</span>
          <a-input v-model:value="showData.tide" disabled/>
        </li>
        <li>
          <span>风向：</span>
          <a-input v-model:value="showData.windDirection" disabled/>
        </li>
        <li>
          <span>风速：</span>
          <a-input v-model:value="showData.windSpeed" disabled/>
        </li>
        <li>
          <span>提交日期：</span>
          <a-input v-model:value="showData.updateTime" disabled/>
        </li>
        <li>
          <span>起始日期：</span>
          <a-input v-model:value="showData.startTime" disabled/>
        </li>
        <li>
          <span>结束日期：</span>
          <a-input v-model:value="showData.endTime" disabled/>
        </li>
        <li>
          <span>所在区块：</span>
          <a-input v-model:value="showData.regionId" disabled/>
        </li>
        <li>
          <span v-show="modalId === false">沙滩沉积类型：</span>
          <a-select v-show="modalId === false" v-model:value="showData.sedimentation_type" style="width: 150px" disabled>
            <a-select-option value="1">砾石</a-select-option>
            <a-select-option value="2">粗砂</a-select-option>
            <a-select-option value="3">细粉沙</a-select-option>
            <a-select-option value="4">粘土</a-select-option>
          </a-select>
        </li>
        <li>
          <span>工作照片：</span>
        </li>
      </ul>
      <div style=" width: 100%; display: flex; flex-wrap: wrap; ">
        <div v-for="photo in showData.photos" :key="photo.photoUrl" style=" position: relative;width: 30%; height:100px; margin: 10px">
          <img v-bind:src="photo.photoUrl" style="  position: absolute;top:50%;left: 50%;transform: translate(-50%, -50%); height:100%; cursor:pointer"  >
        </div>
    </div>
      <a-table :columns="rubbishColumns1" :data-source="rubbishData1" style="margin-top:10px">
        <template #name="{ text }">
          <a>{{ text }}</a>
        </template>
      </a-table>
      <a-table :columns="rubbishColumns2" :data-source="rubbishData2" style="margin-top:10px">
        <template #name="{ text }">
          <a>{{ text }}</a>
        </template>
      </a-table>
    </a-modal>
  </div>

</template>

<script>
import {getAnyDate} from '@/tools/datefmt';
import {defineComponent} from "vue";
import {message} from "ant-design-vue";
import {PlusOutlined, SearchOutlined,DeleteOutlined,ExclamationCircleOutlined } from "@ant-design/icons-vue";
import moment from "moment";
import "moment/dist/locale/zh-cn";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import OSS from "ali-oss";
import { Modal } from 'ant-design-vue';
import { createVNode } from "vue";

moment.locale("zn");

const client = new OSS({
  region: "oss-cn-beijing",
  accessKeyId: "LTAI4G6p1JZZMbXL1KcTJFfB",
  accessKeySecret: "5Og0iIKOSnUss67L2hEjXkCpTUdeCh",
  bucket: "rubbish-photo",
});

//缩略版表的列的信息和数据
const columns = [
  {
    title: "垃圾数目",
    dataIndex: "num",
    key: "num",
    align: "center",
  },
  {
    title: "清理人员",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "区块",
    dataIndex: "regionId",
    key: "regionId",
    align: "center",
  },
  {
    title: "起始时间",
    dataIndex: "startTime",
    key: "startTime",
    align: "center",
  },
  {
    title: "结束时间",
    dataIndex: "endTime",
    key: "endTime",
    align: "center",
  },
  {
    title: "操作",
    slots: {customRender: "action"},
    key: "action",
    align: "center",
  },
];
const columns1 = [
  {
    title: "垃圾数目",
    dataIndex: "num",
    key: "num",
    align: "center",
  },
  {
    title: "清理人员",
    dataIndex: "name",
    key: "name",
    align: "center",
  },
  {
    title: "区块",
    dataIndex: "regionId",
    key: "regionId",
    align: "center",
  },
  {
    title: "起始时间",
    dataIndex: "startTime",
    key: "startTime",
    align: "center",
  },
  {
    title: "结束时间",
    dataIndex: "endTime",
    key: "endTime",
    align: "center",
  },
  {
    title: "操作",
    slots: {customRender: "action"},
    key: "action",
    align: "center",
  },
];
const data = [];
const data1 = [];
//详情表的两个子表的列
const rubbishColumns1 = [
  {
    title: '垃圾类型统计',
    dataIndex: 'name',
    key: 'name',
    align: 'center'
  },
  {
    title: '垃圾数量（个）',
    dataIndex: 'num',
    key: 'num',
    align: 'center'
  },
  {
    title: '垃圾质量（kg）',
    dataIndex: 'weight',
    key: 'weight',
    align: 'center'
  },
  {
    title: '备注',
    dataIndex: 'message',
    key: 'message',
    align: 'center'
  }
];
const rubbishColumns2 = [
  {
    title: '垃圾尺寸',
    dataIndex: 'name',
    key: 'name',
    align: 'center'
  },
  {
    title: '垃圾数量（个）',
    dataIndex: 'num',
    key: 'num',
    align: 'center'
  },
  {
    title: '备注',
    dataIndex: 'message',
    key: 'message',
    align: 'center',
    width: '25%'
  }
];

//详情表对应列的数据
let rubbishData1 = [];
let rubbishData2 = [];

export default defineComponent({
  components: {
    SearchOutlined,
    PlusOutlined,
    DeleteOutlined,
  },
  data() {
    return {
      enUS: enUS,
      zhCN: zhCN,
      //控制删除按钮的展示
      deleteControl: this.$storage.get('userinfo').data.role === 0,
      //控制校验的提醒弹框
      validVisible1: false,
      validVisible2: false,
      validVisible3: false,
      validVisible4: false,
      // 进度条控制相关
      countUp: 0,
      countDone: 0,
      lodaingVisible: false,
      isUp: true,
      // 撤销请求的标志
      remake: 0,
      // base64地址
      base64Url: '',
      // 上传列表
      fileList: [],
      realList: [],
      // 存储的是上传完后的名字
      nameList: [],
      previewVisible: false,
      previewImage: "",
      //日期的选择和搜索，文件导出
      createValue: [getAnyDate(-3), getAnyDate(0)],
      startTime: getAnyDate(-3),
      endTime: getAnyDate(0),
      //上传报表时选择文件的时间
      upCreateValue: [getAnyDate(-7), getAnyDate(0)],
      pagination: {//分页相关
        current: 1,
        pageSize: 8,
        total: 10
      },
      pagination1: {//分页相关
        current: 1,
        pageSize: 8,
        total: 10
      },
      //加载效果
      loading: false,
      loading1:false,
      columns: columns,
      columns1: columns1,
      data: data,
      data1: data1,
      addVisible: false,//插入数据的表单是否显示
      modalId: true,//用于标识是打开的哪一种拟态框
      modalTitle: '垃圾治理项目情况报送表（海漂垃圾）',
      //报送的表单的数据
      inputData: {
        tide: '',//潮汐
        windDirection: '',//风向
        windSpeed: '',//风速
        updateTime: getAnyDate(0),//日期和时间
        startTime: getAnyDate(-7),
        endTime: getAnyDate(0),
        sedimentation_type: '',//沙滩沉积类型
        operatorId: this.$storage.get('userinfo').data.userId,
        regionId: '',
        rubbishTypeList: [
          {//塑料类
            name: 'plastic',
            num: '',//数量
            weight: '',//重量
            regionId: '',//区块
            message: ''//备注
          },
          {//泡沫塑料类
            name: 'foam',
            num: '',//数量
            weight: '',//重量
            regionId: '',//区块
            message: ''//备注
          },
          {//玻璃
            name: 'glass',
            num: '',//数量
            weight: '',//重量
            regionId: '',//区块
            message: ''//备注
          },
          {//金属类
            name: 'metal',
            num: '',//数量
            weight: '',//重量
            regionId: '',//区块
            message: ''//备注
          },
          {//橡胶类
            name: 'rubber',
            num: '',//数量
            weight: '',//重量
            regionId: '',//区块
            message: ''//备注
          },
          {//织物类
            name: 'fabric',
            num: '',//数量
            weight: '',//重量
            regionId: '',//区块
            message: ''//备注
          },
          {//木制品类
            name: 'woodwork',
            num: '',//数量
            weight: '',//重量
            regionId: '',//区块
            message: ''//备注
          },
          {//纸类
            name: 'paper',
            num: '',//数量
            weight: '',//重量
            regionId: '',//区块
            message: ''//备注
          },
          {//无法辨别类
            name: 'unknown',
            num: '',//数量
            weight: '',//重量
            regionId: '',//区块
            message: ''//备注
          },
        ],
        rubbishSizeList: [
          {//小型垃圾
            name: 'small_rubbish',
            num: '',
            regionId: '',
            message: ''
          },
          {//中型垃圾
            name: 'middle_rubbish',
            num: '',
            regionId: '',
            message: ''
          },
          {//大型垃圾
            name: 'large_rubbish',
            num: '',
            regionId: '',
            message: ''
          },
          {//特大型垃圾
            name: 'very_large_rubbish',
            num: '',
            regionId: '',
            message: ''
          },
        ],
        photoUrls: [],
      },
      // 控制显示的是撤销表格还是总的表格
      isRemakeTable: false,
      showVisible: false,//展示数据的表格是否显示
      showData: {
        tide: '',//潮汐
        windDirection: '',//风向
        windSpeed: '',//风速
        updateTime: '',//日期和时间
        sedimentation_type: '',//沙滩沉积类型
        name: '',//填报人
        phone: '',//联系方式
        regionId: '',
        photos: [],
      },
      //详细信息表格中的数据格式
      rubbishColumns1: rubbishColumns1,
      rubbishColumns2: rubbishColumns2,
      rubbishData1: rubbishData1,
      rubbishData2: rubbishData2,
    };
  },
  methods: {
    //查询有撤销申请的报表
    searchRemakeReport() {
      this.isRemakeTable = true;
      this.loading1 = true;
      let that = this;
      let url = 'http://39.100.158.75:8080/garbageClean/findEasyChart';
      this.$axios({
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        url,
        params: {
          limit: that.pagination1.pageSize,
          page: that.pagination1.current,
          startTime: '',
          endTime: '',
          remake: 1,
        }
      }).then(res => {
        if (res.data.data === null) {
          message.info(res.data.message);
          this.data1 = [];
          this.pagination1.total = 0;
          this.$refs.btnRef.$el.innerText = "查看撤销请求"+"("+this.data1.length+")"
        } else {
          if (res.data.success === true) {
            message.info(res.data.message);
            this.data1 = res.data.data.list;
            this.pagination1.total = res.data.data.totalPageCount;
            this.$refs.btnRef.$el.innerText = "查看撤销请求"+"("+this.data1.length+")";
          } else {
            message.info('查询失败，请重试');
            this.$refs.btnRef.$el.innerText = "查看撤销请求";
          }
        }
        this.loading1 = false;
      });
    },
    //撤销请求
    deleteRequest(text) {
      console.log(text);
      let that = this;
       Modal.confirm({
          title: '撤销申请',
          icon: createVNode(ExclamationCircleOutlined),
          content: createVNode('div', {style: 'color: red;'}, '本次操作将会通知管理员删除所选报表！'),
          okText: '确定',
          cancelText: '取消',
          onOk() {
            that.$axios({
              headers: {
                "Content-Type": "application/json",
                token: that.$storage.get('userinfo').data.token,
              },
              url:"http://39.100.158.75:8080/garbageClean/updateRemake",
              method: "GET",
              params: {
                chartId: text.id,
                remake: 1,
              }
            }).then((res) => {
              
              if(res.data.success) {
                that.$message.success(res.data.message)
              }
            })
          },
          onCancel() {}
      });
    },
    // 上传前
    beforeUpload(file) {
      const thePicture =
          file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/jpg";
      if (this.fileList.length > 11) {
        return false
      } else if (!thePicture) {
        message.error(file.name + '文件格式错误!请重新上传正确格式文件')
      }
      this.exeBase(file)
      return false
    },
    // 上传时
    handleChange(file) {
      // 确保只有上传图片时才会进行handlechange 如果是删除图片确保这个函数不作为
      if(this.isUp === false) {
        this.isUp = true;
        return ;
      }
      let name = file.file.name; //获取文件名字
      let file2 = file.file;
      let obj = new Date().getTime(); //按时间命名，文件名字
      let storeAs =
          obj + "." + name; //file文件总名字
      this.nameList.push(storeAs)
      //上传第一张图片之前开启等待框
        this.lodaingVisible = true;
      //上传oss成功后增加一个countup的值
      this.countUp++;
      client.put(storeAs, file2).then((results) => {
        this.inputData.photoUrls.push(results.url)
        this.realList.push(file2);
        //上传成功后增加一个成功的图片
        this.countDone++
        if(this.countDone === this.countUp) {
          this.lodaingVisible = false;
          this.countDone = 0;
          this.countUp = 0;
        }
      })
    },
    // 在这里异步等待base64转码的结果
    async exeBase(file) {
      await this.getBase64(file, (imageUrl) => {
        if (this.fileList.length < 13) {
          file.url = imageUrl
          this.loading = false
        }
      })
    },
    // 获取base64的编码
    getBase64(file, callback) {
      return new Promise((resolve) => {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          callback(reader.result)
          resolve('成功')
        }
      })
    },
    getBase(file) {
      this.base64Url = '';
      let that = this;
      let imgFile = new FileReader();
      imgFile.readAsDataURL(file);
      imgFile.onload = function () {
        console.log(this.result);
        that.base64Url = this.result.toString().replace('data:image/jpeg;base64,', '');
      }
    },
    handlePreview(file) {//点击查看大图
      let original = file.originFileObj;
      this.previewImage = URL.createObjectURL(original);
      this.previewVisible = true
    },
    // 这里用来移除图片中的某一项
    async handleRemove(file) {
      let file2 = file.originFileObj;
      const index = this.fileList.indexOf(file);
      //这个index1存储的是真正的文件上传顺序的数组
      const index1 = this.realList.indexOf(file2);
      // 删除时不打开加载框
      this.isUp = false;
      // 删除成功后打开加载框控制变量
     await client.delete(this.nameList[index]).then(() => {});
      const newPhotoList = this.inputData.photoUrls.slice()
      const newNameList = this.nameList.slice()
      const newFileList = this.fileList.slice()
      const newRealList = this.realList.slice()
      newPhotoList.splice(index1, 1)
      newNameList.splice(index, 1)
      newFileList.splice(index, 1)
      newRealList.splice(index1,1)
      this.inputData.photoUrls = newPhotoList
      this.nameList = newNameList
      this.fileList = newFileList
      this.realList = newRealList
    },
    handleCancelImg() {//点击关闭图片预览
      this.previewVisible = false;
    },
    //获取打开时的列表（概述信息）
    getTableData() {
      this.loading = true;
      let url = 'http://39.100.158.75:8080/garbageClean/findEasyChart';
      this.$axios({
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        url,
        params: {
          limit: this.pagination.pageSize,
          page: this.pagination.current,
          startTime: this.startTime,
          endTime: this.endTime
        }
      }).then(res => {
        this.remake = 0;
        if (res.data.data == null) {
          message.info(res.data.message);
          this.data = [];
          this.pagination.total = 0;
        } else {
          
          if (res.data.success === true) {
            message.info(res.data.message);
            this.data = res.data.data.list;
            this.pagination.total = res.data.data.totalPageCount;
          } else {
            message.info('查询失败，请重试');
          }
        }
        this.loading = false;
      });
    },
    //概述信息表换页的时候触发
    changeMessageData(event) {
      this.pagination.current = event.current;
      this.getTableData();
    },
    changeMessageData1(event) {
      this.pagination1.current = event.current;
      this.searchRemakeReport();
    },
    //填送报表打开后的效果
    rubbishManage(id) {
      this.addVisible = true;
      this.modalId = id;
      if (id === false) {
        this.modalTitle = '垃圾治理项目情况报送表（海滩垃圾）';
      } else {
        this.modalTitle = '垃圾治理项目情况报送表（海漂垃圾）';
      }
    },
    //报表信息清空函数
    emptyTable() {
      this.inputData.tide = '';
      this.inputData.windDirection = '';
      this.inputData.windSpeed = '';
      this.inputData.startTime = getAnyDate(-7);
      this.inputData.endTime = getAnyDate(0);
      this.inputData.sedimentation_type = '';
      this.inputData.rubbishTypeList[0].num = '';
      this.inputData.rubbishTypeList[0].weight = '';
      this.inputData.rubbishTypeList[0].regionId = '';
      this.inputData.rubbishTypeList[0].message = '';

      this.inputData.rubbishTypeList[1].num = '';
      this.inputData.rubbishTypeList[1].weight = '';
      this.inputData.rubbishTypeList[1].regionId = '';
      this.inputData.rubbishTypeList[1].message = '';

      this.inputData.rubbishTypeList[2].num = '';
      this.inputData.rubbishTypeList[2].weight = '';
      this.inputData.rubbishTypeList[2].regionId = '';
      this.inputData.rubbishTypeList[2].message = '';

      this.inputData.rubbishTypeList[3].num = '';
      this.inputData.rubbishTypeList[3].weight = '';
      this.inputData.rubbishTypeList[3].regionId = '';
      this.inputData.rubbishTypeList[3].message = '';

      this.inputData.rubbishTypeList[4].num = '';
      this.inputData.rubbishTypeList[4].weight = '';
      this.inputData.rubbishTypeList[4].regionId = '';
      this.inputData.rubbishTypeList[4].message = '';

      this.inputData.rubbishTypeList[5].num = '';
      this.inputData.rubbishTypeList[5].weight = '';
      this.inputData.rubbishTypeList[5].regionId = '';
      this.inputData.rubbishTypeList[5].message = '';

      this.inputData.rubbishTypeList[6].num = '';
      this.inputData.rubbishTypeList[6].weight = '';
      this.inputData.rubbishTypeList[6].regionId = '';
      this.inputData.rubbishTypeList[6].message = '';

      this.inputData.rubbishTypeList[7].num = '';
      this.inputData.rubbishTypeList[7].weight = '';
      this.inputData.rubbishTypeList[7].regionId = '';
      this.inputData.rubbishTypeList[7].message = '';

      this.inputData.rubbishTypeList[8].num = '';
      this.inputData.rubbishTypeList[8].weight = '';
      this.inputData.rubbishTypeList[8].regionId = '';
      this.inputData.rubbishTypeList[8].message = '';

      this.inputData.rubbishSizeList[0].num = '';
      this.inputData.rubbishSizeList[0].regionId = '';
      this.inputData.rubbishSizeList[0].message = '';

      this.inputData.rubbishSizeList[1].num = '';
      this.inputData.rubbishSizeList[1].regionId = '';
      this.inputData.rubbishSizeList[1].message = '';

      this.inputData.rubbishSizeList[2].num = '';
      this.inputData.rubbishSizeList[2].regionId = '';
      this.inputData.rubbishSizeList[2].message = '';

      this.inputData.rubbishSizeList[3].num = '';
      this.inputData.rubbishSizeList[3].regionId = '';
      this.inputData.rubbishSizeList[3].message = '';
      this.inputData.photoUrls = [];
    },
    //提交之前的表单验证
    valid() {
      var intValid = /(^$)|(^\d+$)/;
      var pointValid =  /(^$)|^(\d+(\.\d+)?$)/;
      var countValid = 0;
      if( this.inputData.regionId === '') {
         this.validVisible4 = true;
         return false;
      }
      for(let i = 0 ;i < 8;i++) {
        console.log(this.inputData.rubbishTypeList[i].num);
        if(!intValid.test(this.inputData.rubbishTypeList[i].num)) {
          console.log(this.inputData.rubbishTypeList[i].num);
          countValid++;
          break;
        }
      }
      for(let i =0 ;i < 4;i++) {
        if(!intValid.test( this.inputData.rubbishSizeList[i].num) && countValid === 0) {
          countValid++;
          break;
        }
      }
      for(let i =0 ;i < 8;i++) {
        if(!pointValid.test(this.inputData.rubbishTypeList[i].weight)) {
          countValid+=2;
          break;
        }
      }
      console.log(countValid);
      if(countValid === 1) {
       this.validVisible1 = true;
       return false;
      } else if(countValid === 2) {
       this.validVisible2 = true;
       return false;
      } else if(countValid === 3) {
       this.validVisible3 = true;
       return false;
      }
      return true;
    },
    handleCancel1() {
      this.validVisible1 = false;
    },
    handleCancel2() {
      this.validVisible2 = false;
    },
    handleCancel3() {
      this.validVisible3 = false;
    },
    handleCancel4() {
      this.validVisible4 = false;
    },
  //提交报表的时候触发
    doAddKeywords() {
      
      this.inputData.rubbishTypeList[1].regionId = this.inputData.rubbishTypeList[0].regionId;
      this.inputData.rubbishTypeList[2].regionId = this.inputData.rubbishTypeList[0].regionId;
      this.inputData.rubbishTypeList[3].regionId = this.inputData.rubbishTypeList[0].regionId;
      this.inputData.rubbishTypeList[4].regionId = this.inputData.rubbishTypeList[0].regionId;
      this.inputData.rubbishTypeList[5].regionId = this.inputData.rubbishTypeList[0].regionId;
      this.inputData.rubbishTypeList[6].regionId = this.inputData.rubbishTypeList[0].regionId;
      this.inputData.rubbishTypeList[7].regionId = this.inputData.rubbishTypeList[0].regionId;
      this.inputData.rubbishTypeList[8].regionId = this.inputData.rubbishTypeList[0].regionId;
      this.inputData.rubbishSizeList[0].regionId = this.inputData.rubbishTypeList[0].regionId;
      this.inputData.rubbishSizeList[1].regionId = this.inputData.rubbishTypeList[0].regionId;
      this.inputData.rubbishSizeList[2].regionId = this.inputData.rubbishTypeList[0].regionId;
      this.inputData.rubbishSizeList[3].regionId = this.inputData.rubbishTypeList[0].regionId;
      this.inputData.regionId = this.inputData.rubbishTypeList[0].regionId;
      if(!this.valid()) return ;
      this.addVisible = false;
      let url = 'http://39.100.158.75:8080/garbageClean/insertChart';
      console.log("海漂：");
      console.log(this.inputData);
      this.$axios({
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        url,
        method: 'POST',
        data: JSON.stringify(this.inputData)
      }).then(res => {
        if (res.data.success === true) {
          message.info(res.data.message);
          this.getTableData();
        } else {
          message.info(res.data.message);
        }
        this.emptyTable();
        this.fileList = [];
        this.nameList = [];
        this.upCreateValue = [getAnyDate(-7), getAnyDate(0)];
        this.getTableData();
      });
    },
    //取消填送报表时触发
    handleCancel() {
      this.addVisible = false;
      for(const name of this.nameList) {
        client.delete(name);
      } 
      this.fileList = [];
      this.nameList = [];
      this.upCreateValue = [getAnyDate(-7), getAnyDate(0)];
      this.emptyTable();
    },
    //搜索
    searchTable() {
      this.isRemakeTable = false;
      this.getTableData();
    },
    //搜索的时候用于绑定日期格式
    onChange(value, dateString) {
      this.createValue = value;
      this.startTime = dateString[0];
      this.endTime = dateString[1];
    },

    //上传的时候用于绑定日期格式
    upOnChange(value, dateString) {
      this.upCreateValue = value;
      this.inputData.startTime = dateString[0];
      this.inputData.endTime = dateString[1];
    },
    //导出简单excel数据
    exportExcelData() {
      let url = 'http://39.100.158.75:8080/garbageClean/exportOneChart';
      this.$axios({
        params: {
          limit: this.pagination.pageSize,
          page: this.pagination.current,
          startTime: this.startTime,
          endTime: this.endTime
        },
        method: 'GET',
        url: url,
        responseType: 'blob',
      }).then(res => {
        const link = document.createElement('a');
        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
        link.style.display = 'none';
        link.href = URL.createObjectURL(blob);
        link.download = '清理报表简要数据' + this.startTime + '-----' + this.endTime;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
    },
    //导出详细的word报表数据
    exportWordData(number1) {
      window.location.href = 'http://39.100.158.75:8080/garbageClean/exportOneChartWord' + '?chartId=' + number1;
    },
    // 删除某个报表
    deleteTable(text) {
      console.log(text);
      let that = this;
      Modal.confirm({
          title: '你确定要删除这张图片吗？',
          icon: createVNode(ExclamationCircleOutlined),
          content: createVNode('div', {style: 'color: red;'}, '本次操作将会永久地删除此报表！'),
          okText: '确定',
          cancelText: '取消',
          onOk() {
            let url = 'http://39.100.158.75:8080/garbageClean/deleteOneChart';
        that.$axios({
        headers: {
          token: that.$storage.get("userinfo").data.token,
        },
        url,
        method: 'GET',
        params: {
          chartId: text.id
        }
      }).then(res => {
        console.log(res)
        if(that.isRemakeTable) {
           let pagNum = that.pagination1.total / that.pagination1.pageSize;
          if((that.pagination1.total-1) % that.pagination1.pageSize === 0 && (that.pagination1.current) > pagNum){                     
          that.pagination1.current =
          that.pagination1.current - 1 > 0 ? that.pagination1.current - 1 : 1;
          }
          that.searchRemakeReport();
        } else {
          let pagNum = that.pagination.total / that.pagination.pageSize;
          if((that.pagination.total-1) % that.pagination.pageSize === 0 && (that.pagination.current) > pagNum){                     
          that.pagination.current =
          that.pagination.current - 1 > 0 ? that.pagination.current - 1 : 1;
          }
          that.getTableData();
        }
      });
          },
          onCancel() {}
        });
    },
    //点击表格弹出详细信息
    getDetailData(number1) {
      let url = 'http://39.100.158.75:8080/garbageClean/findOneChart';
      this.$axios({
        headers: {
          "Content-Type": "application/json",
          token: this.$storage.get("userinfo").data.token,
        },
        method: 'GET',
        url,
        params: {
          chartId: number1
        }
      }).then(res => {
        if (res.data.success === true) {
          message.info(res.data.message);  
          let rId = res.data.data.rubbishTypeList[0].regionId;      
          if(res.data.data.sedimentation_type === '0') {
            this.showData.regionId = rId === 1? 'A' :(rId === 4 ? 'B': (rId === 6 ? 'C': ''))
          } else {
            this.showData.regionId = rId;
          }
          this.showVisible = true;
          this.showData.tide = res.data.data.tide;
          this.showData.windDirection = res.data.data.windDirection;
          this.showData.windSpeed = res.data.data.windSpeed;
          this.showData.updateTime = res.data.data.updateTime;
          this.showData.startTime = res.data.data.startTime;
          this.showData.endTime = res.data.data.endTime;
          this.showData.name = res.data.data.name;
          this.showData.sedimentation_type = res.data.data.sedimentation_type;
          this.showData.phone = res.data.data.phone;
          this.showData.photos = res.data.data.photos;
          this.rubbishData1 = res.data.data.rubbishTypeList;
          this.rubbishData2 = res.data.data.rubbishSizeList;
        } else {
          message.info(res.data.message);
        }
      });
    },
    showDetails(text) {
      this.getDetailData(text.id);
    },
    //详细信息查看的时候用
    showConfirm() {
      this.showVisible = false;
    },
    showCancel() {
      this.showVisible = false;
    },
  },
  mounted() {
    this.getTableData();
    this.searchRemakeReport();
    this.isRemakeTable = false;
    message.warning('本页面建议横屏使用！', 4);
  },
});
</script>

<style lang="scss" scoped>
:deep().ant-table-tbody > tr > td {
  padding: 10px;
}
.top-nav {
  padding: 5px 20px;
  .search_btn {
    margin-left: 20px;
  }
  .load_btn {
    margin-left: 10px;
  }
  .table_btn {
    margin-left: 20px;
  }
}
.chart-top {
  padding: 10px 5px;
  margin-left: 50px;
  .search_btn {
    margin-left: 10px;
  }
  .load_btn {
    margin-left: 10px;
  }
  .table_btn {
    margin-left: 20px;
  }
}
ul {
  list-style: none;
}
.input_list {
  padding: 0;
  li {
    line-height: 44px;
    span {
      display: inline-block;
      width: 120px;
    }
    input {
      width: 180px;
    }
  }
  #title-table {
    line-height: 40px;
    width: 95%;
    span {
      font-weight: 800;
      border-bottom: 1px solid #ccc;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    #first {
      width: 130px;
    }
    #common {
      width: 140px;
    }
    #note {
      width: 180px;
    }
  }
  #table {
    line-height: 40px;
    width: 95%;
    #first {
      width: 130px;
    }
    #common {
      width: 120px;
    }
    #note {
      width: 180px;
    }
  }
  #title-table2 {
    line-height: 40px;
    width: 95%;
    span {
      font-weight: 800;
      border-bottom: 1px solid #ccc;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    #first {
      width: 250px;
    }
    #common {
      width: 140px;
    }
    #note {
      width: 240px;
    }
  }
  #table2 {
    line-height: 40px;
    width: 95%;
    #first {
      width: 250px;
    }
    #common {
      width: 130px;
    }
    #note {
      width: 240px;
    }
  }
}
.btn {
  margin-left: 10px;
}
.chart-global-box {
  width: 99%;
  height: 30px;
  border: 1px solid pink;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .chart-box {
    width: 33%;
    height: 20px;
    border: 1px solid blue;
  }
}
//自定义模态框样式
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
}
.modal {
  background-color: #fff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x:auto;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  width: 1200px;
}
.modal-header {
  border-bottom: 1px solid #eee;
  color: #313131;
  justify-content: space-between;
  padding: 15px;
  padding-bottom: 5px;
  display: flex;
}
.modal-footer {
  border-top: 1px solid #eee;
  justify-content: flex-end;
  padding: 15px;
  display: flex;
}
.modal-body {
  position: relative;
  padding: 10px 10px;
  max-height: 630px;
  overflow-y: auto;
  overflow-x: hidden;
}
.modal-body::-webkit-scrollbar {
  display: none;
}
.btn-close, .btn-confirm {
  border-radius: 8px;
  margin-left:16px;
  width:56px;
  height: 36px;
  border:none;
  cursor: pointer;
}
.btn-close {
  color: #313131;
  background-color:transparent;
}
.btn-confirm {
  color: #fff;
  background-color: #2d8cf0;
}
</style>